import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('order');
  const settings = JSON.parse(element.getAttribute('shop'));
  //const pid = JSON.parse(element.getAttribute('pid'));
  //const amnt = JSON.parse(element.getAttribute('amnt'));

  const app = new Vue({
    el: '#order',
    data: {
      well_types: ['Porakaivo', 'Rengaskaivo'],
      selected_well: '',
      selected_type: 'laaja',
      analysis_types: ['Laaja analyysi', 'Suppea analyysi'],
      analysis_amount: 1,

      rengas_laaja_info: element.getAttribute('rwla_desc'),
      //'<strong>Laaja rengaskaivon analyysi</strong><br>Laaja analyysi sisältää seuraavat analyysit: Haju, ulkonäkö, Escherichia coli ja kolimuotoiset bakteerit, enterokokit, pH, sähkönjohtavuus, ammonium, fluoridi, kloridi, nitraatti, nitriitti, sulfaatti, CODMn (humus), kokonaiskovuus (Ca + Mg), alumiini, mangaani ja rauta.',
      rengas_suppea_info: element.getAttribute('rwsa_desc'),
      //'<strong>Suppea rengaskaivon analyysi</strong><br>Suppea analyysi sisältää seuraavat analyysit:  Haju, ulkonäkö, Escherichia coli ja kolimuotoiset bakteerit, enterokokit, pH, sähkönjohtavuus, nitraatti, kloridi, CODMn (humus), mangaani ja rauta.',
      pora_laaja_info: element.getAttribute('dwla_desc'),
      //'<strong>Laaja porakaivon analyysi</strong><br>Laaja analyysi sisältää seuraavat analyysit: Haju, ulkonäkö, Escherichia coli ja kolimuotoiset bakteerit, enterokokit, pH, sähkönjohtavuus, fluoridi, kloridi, sulfaatti, CODMn (humus), kokonaiskovuus (Ca + Mg), arseeni, rauta, mangaani, uraani ja radon.',
      pora_suppea_info: element.getAttribute('dwsa_desc'),
      //'<strong>Suppea porakaivon analyysi</strong><br>Suppea analyysi sisältää seuraavat analyysit: Haju, ulkonäkö, Escherichia coli ja kolimuotoiset bakteerit, enterokokit, pH, sähkönjohtavuus, fluoridi, arseeni, rauta, mangaani ja radon.',

      info_text: '',
      price: '',

      rengas_laaja_price: settings.ringwell_large_analysis_price,
      rengas_suppea_price: settings.ringwell_small_analysis_price,
      pora_laaja_price: settings.drillwell_large_analysis_price,
      pora_suppea_price: settings.drillwell_small_analysis_price,

      //preselect_pid: pid,
      //preselect_a: amnt
    },

    mounted() {
    },

    methods: {
      wellOnChange(event) {
        if (event.target.value == "Rengaskaivo") {
          this.analysis_types = [ 'Laaja analyysi', 'Suppea analyysi']
          this.selected_well = 'rengaskaivo'
        } else if (event.target.value == "Porakaivo") {
          this.analysis_types = [ 'Laaja analyysi', 'Suppea analyysi']
          this.selected_well = 'porakaivo'

        }
        this.textAndPriceChange()
      },

      textAndPriceChange() {
        if (this.selected_well == "rengaskaivo" && this.selected_type == "laaja") {
          this.info_text = this.rengas_laaja_info
          this.price = (this.analysis_amount * this.rengas_laaja_price) + " eur"
        } else if (this.selected_well == "rengaskaivo" && this.selected_type == "suppea") {
          this.info_text = this.rengas_suppea_info
          this.price = (this.analysis_amount * this.rengas_suppea_price) + " eur"
        }

        if (this.selected_well == "porakaivo" && this.selected_type == "laaja") {
          this.info_text = this.pora_laaja_info
          this.price = (this.analysis_amount * this.pora_laaja_price) + " eur"
        } else if (this.selected_well == "porakaivo" && this.selected_type == "suppea") {
          this.info_text = this.pora_suppea_info
          this.price = (this.analysis_amount * this.pora_suppea_price) + " eur"
        }
      },

      typeOnChange(event) {
        if (event.target.value == "Laaja analyysi") {
          this.selected_type = "laaja"
        } else if (event.target.value == "Suppea analyysi") {
          this.selected_type = "suppea"
        }
        this.textAndPriceChange()
      },

      amountOnChange(event) {
        this.analysis_amount = event.target.value
        this.textAndPriceChange()
      },

      order() {
        if ( (this.selected_type == "laaja" || this.selected_type == "suppea") && (this.selected_well == "rengaskaivo" || this.selected_well == "porakaivo") ) {
          window.location.href = "/order?well=" + this.selected_well + "&type=" + this.selected_type + "&amount=" + this.analysis_amount;
        }
      }

    },
  })
})
